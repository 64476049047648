import React, {Component} from 'react';
import "./contactSection.scss";


class ContactSection extends Component {

    render(){
        return(
            <div className="container contactForm">
            <div className="contact-section-heading">
            <h1>Contact Form</h1>
            </div>
            <form action="https://formspree.io/f/1@andrewcrowe.nz" method="POST">
                {/* <input type="text" id="fname-field" name="firstName" placeholder="Your name"/> */}
                <input type="text" id="email-field" name="email" placeholder="Your email"/>
                {/* <input type="text" id="phone-field" name="phone" placeholder="Your phone number"/> */}
                <textarea  rows="10" id="subject-field" name="subject" placeholder="Message" ></textarea>
              
              <input type="submit" value="Submit"/>
          
            </form>
          </div>
            
        )
    }
}


export default ContactSection