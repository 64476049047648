import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from '../layout/layout'
import ContactForm from '../components/contactForm/contactSection'
import './page.scss'
import './content-classes/wp-classes.scss'
import Recent from '../components/recent/index'
import Seo from '../components/seo/'


class Page extends Component {
  render() {
    const StaticPage = this.props.data.wordpressPage

    return (
      <>
        <Layout>
        <Seo postTitle={StaticPage.title}/>
          <div className="post_content page_content">
            <h1>{StaticPage.title}</h1>
            <div className="wordpress_page_content" dangerouslySetInnerHTML={{ __html: StaticPage.content }} />
        </div>
        <Recent/>
        <ContactForm/>
      </Layout>
      </>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
