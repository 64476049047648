import React from "react"
import { Link } from "gatsby"
import "./recent.scss"

export default function Recent(props) {
  return (
      <div className="recent"> 
        <h1>Recent Posts</h1>
        <div>
        {console.log("recent posts:")}
      {console.log(props)}
        {props.data.map(({ node }) => (
            <div>
               <Link to={"/blog/" + node.slug}> 
            { (node.images.squareFeaturedImage != null)
            ? <picture className='recent_picture'> <img alt="" srcSet={node.images.squareFeaturedImage.localFile.childImageSharp.fixed.srcSet}/> </picture>
            :  node.title
            }
            </Link>
              </div>
            ))}     
          </div>
      </div>
  )
}